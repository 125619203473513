import React, { useState, useEffect, useCallback } from "react";
import api from "../../utils/api";
const GoogleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API;


const EstimationTemps = ({
    latOrigine,
    lngOrigine,
    latDestination,
    lngDestination,
    step1 = null, // Étape 1 sous forme d'adresse
    step2 = null, // Ét
}) => {
    const [tempsEstime, setTempsEstime] = useState(null);

    useEffect(() => {
        const calculerTempsEstime = async () => {
            let waypoints = "";

            // Ajoute step1 si elle n'est ni vide ni null
            if (step1 !== '' && step1 !== null) {
                waypoints += step1;
            }

            // Ajoute step2 si elle n'est ni vide ni null, avec un séparateur si step1 est déjà présent
            if (step2 !== '' && step2 !== null) {
                waypoints += waypoints ? `|${step2}` : step2;
            }

            const response = api.get(
                `/directions?origin=${latOrigine},${lngOrigine}&destination=${latDestination},${lngDestination}${waypoints ? `&waypoints=${waypoints}` : ""
                }&key=${GoogleMapsAPIKey}`
            );

            const res = await response;
            const data = res.data;
            if (data.routes.length > 0) {
                const duree = data.routes[0].legs[0].duration_in_traffic.text;
                setTempsEstime(duree);
            }
        };

        calculerTempsEstime();
    }, [latOrigine, lngOrigine, latDestination, lngDestination]);

    return (
        <div>Estimation time : {tempsEstime ? tempsEstime : "Calculation..."}</div>
    );
};

export default EstimationTemps;
