import React, { useEffect, useState, useRef } from "react";
import api from "../../utils/api";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import TransferRow from "./TransferRow"; // Import du nouveau composant
import "./Transfers.css";
import { useSwipe } from "./SwipeContext"; // Importer le contexte
import { PaymentMethodModal, choosePaymentMethod } from "./transferFunctions"; // Import de la modal pour les paiements
import moment from "moment"; // Importation de moment
import { useDoubleTap } from "use-double-tap";

function Transfers() {
  const [isLoading, setLoading] = useState(true);
  const [transfers, setTransfers] = useState([]);
  const [chauffeurs, setChauffeurs] = useState([]);
  const [selectedChauffeur, setSelectedChauffeur] = useState(null);
  const [filteredTransfers, setFilteredTransfers] = useState(null); // Nouvel état pour les transferts filtrés
  const [hasScrolled, setHasScrolled] = useState(false); // Ajout d'un état pour contrôler le scroll
  const closestTransferRef = useRef(null); // Ref pour la course la plus proche

  const [selectedTransferForPayment, setSelectedTransferForPayment] =
    useState(null); // État pour la modal de paiement
  const [doubleTappedChauffeur, setDoubleTappedChauffeur] = useState(null); // New state to track double-tapped chauffeur

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const { isSwiping } = useSwipe(); // Obtenir l'état du swipe
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const handleClosePaymentMethod = () => setShowPaymentMethod(false);

  const goToResa = (data) => {
    navigate("/transfers/" + data.IdResa, { state: data });
  };

  const setBgColor = (montant, etat, driver_name) => {
    if (etat === "Averifier") return "bg-info";
    if (Math.round(montant) === 0 || etat === "Annule") return "bg-danger"; // Si annulé ou montant à zéro, rouge
    if (etat === "Valide" && driver_name !== null) return "bg-white"; // Si l'état est valide et qu'un chauffeur est affecté, blanc
    if (etat === "Attente" || driver_name === null) return "bg-warning"; // Si en attente ou pas de chauffeur, jaune
    return "bg-white"; // Par défaut blanc si validé
  };

  const handleCourseClick = (course) => () => {
    if (isSwiping) {
      return;
    }
    if (selectedChauffeur) {
      assignDriverToCourse(course.IdResa, selectedChauffeur.id);
      setTransfers(
        transfers.map((t) =>
          t.IdResa === course.IdResa
            ? { ...t, driver_name: selectedChauffeur.name, Etat: "Valide" }
            : t
        )
      );
      setSelectedChauffeur(null);
    } else {
      goToResa(course);
    }
  };

  const fetchChauffeurs = async () => {
    try {
      const response = await api.get("/drivers");
      setChauffeurs(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des chauffeurs :", error);
    }
  };

  const fetchTransfers = async (status) => {
    try {
      let URL = "/transfers";
      if (status !== null) URL += "?status=" + status;
      const response = await api.get(URL);
      const allTransfers = response.data;

      const closestTransfer = allTransfers.reduce((closest, current) => {
        const currentDateTime = moment(current.TimeResa); // Utiliser TimeResa comme DateTime
        const closestDateTime = moment(closest?.TimeResa); // Utiliser TimeResa pour closest
        const now = moment(); // Obtenir l'heure actuelle

        // Comparer la date et l'heure pour trouver la course la plus proche
        if (currentDateTime.isAfter(now)) {
          if (
            !closest ||
            currentDateTime.diff(now) < closestDateTime.diff(now)
          ) {
            return current;
          }
        }
        return closest;
      }, null);

      if (closestTransfer) {
        closestTransferRef.current = closestTransfer; // Enregistrer l'ID de la course la plus proche
      }
      setTransfers(allTransfers);
    } catch (error) {
      console.error("Erreur lors de la récupération des transferts :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChauffeurs();
    fetchTransfers(status);
  }, [status]);

  // useEffect pour scroller vers la course la plus proche une fois les transferts disponibles
  useEffect(() => {
    if (!isLoading && closestTransferRef.current && hasScrolled === false) {
      const uniqueId = closestTransferRef.current.IdChauffeur
        ? `transfer-${closestTransferRef.current.IdResa}-${closestTransferRef.current.IdChauffeur}`
        : `transfer-${closestTransferRef.current.IdResa}-no-driver`;
      const element = document.getElementById(uniqueId);

      if (element) {
        element.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
      setHasScrolled(true);
    }
  }, [isLoading, transfers, hasScrolled]);

  const assignDriverToCourse = async (courseId, driverId) => {
    try {
      const response = await api.post(`/transfers/${courseId}/assign-driver`, {
        driver_id: driverId,
      });
      fetchTransfers(status); // Appelle la fonction qui récupère les transferts
      console.log("Chauffeur assigné avec succès", response.data);
    } catch (error) {
      console.error("Erreur lors de l'assignation du chauffeur :", error);
    }
  };

  const unassignDriver = async (courseId, driverId) => {
    try {
      await api.delete(`/transfers/${courseId}/unassign-driver/${driverId}`);
      fetchTransfers(status); // Appelle la fonction qui récupère les transferts
      console.log("Chauffeur désassigné avec succès et transferts mis à jour");
    } catch (error) {
      console.error("Erreur lors de la désassignation du chauffeur :", error);
    }
  };

  // Fonction pour ouvrir la modal de paiement
  const handleOpenPaymentMethod = (transferId) => {
    setSelectedTransferForPayment(transferId); // Enregistre l'ID du transfert pour lequel la modal s'ouvre
    setShowPaymentMethod(true); // Affiche la modal
  };

  // Fonction pour regrouper les transferts par date
  const groupTransfersByDate = (transfers) => {
    return transfers.reduce((groupedTransfers, transfer) => {
      const date = moment(transfer.DateResa).format("YYYY-MM-DD"); // Utilisation de moment pour formater la date
      if (!groupedTransfers[date]) {
        groupedTransfers[date] = [];
      }
      groupedTransfers[date].push(transfer);
      return groupedTransfers;
    }, {});
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status" variant="success">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // console.log(transfers);

  // Gestion du simple tap et du double tap sur un chauffeur
  const handleChauffeurSingleTap = (chauffeur) => {
    if (selectedChauffeur === chauffeur) {
      setSelectedChauffeur(null);
      return;
    }
    setSelectedChauffeur(chauffeur);
    setFilteredTransfers(null); // Clear filter on single tap
    setDoubleTappedChauffeur(null); // Reset double-tap styling
  };

  const handleChauffeurDoubleTap = (chauffeur) => {
    const chauffeurTransfers = transfers.filter(
      (transfer) => transfer.IdChauffeur === chauffeur.id
    );
    console.log(chauffeurTransfers);
    setDoubleTappedChauffeur(chauffeur); // Set the double-tapped chauffeur to apply color change
    setFilteredTransfers(chauffeurTransfers);
    setSelectedChauffeur(null);
  };

  const ChauffeurItem = ({ chauffeur }) => {
    const bind = useDoubleTap(
      (event) => handleChauffeurDoubleTap(chauffeur), // Double tap action
      200, // Threshold for double tap (300ms)
      {
        onSingleTap: (event) => handleChauffeurSingleTap(chauffeur), // Single tap action
      }
    );

    return (
      <div
        key={chauffeur.id}
        {...bind} // Spread the onClick handler provided by the useDoubleTap hook
        className="driver_picto"
        style={{
          userSelect: "none",
          backgroundColor:
            doubleTappedChauffeur?.id === chauffeur.id // If double-tapped, apply green color
              ? "green"
              : selectedChauffeur?.id === chauffeur.id // Otherwise, show the selection color
              ? "#40cdf9"
              : "#d9f6ff", // Default color
        }}
      >
        {chauffeur.name}
      </div>
    );
  };

  return (
    <Container fluid style={{ paddingBottom: "110px" }}>
      {(localStorage.getItem("role") === "Administrateur" ||
        localStorage.getItem("role") === "Super Administrateur") && (
        <Row>
          <Col>
            <div className="drivers_list">
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                {chauffeurs.map((chauffeur) => (
                  <ChauffeurItem key={chauffeur.id} chauffeur={chauffeur} />
                ))}
              </div>
            </div>
          </Col>
        </Row>
      )}

      <Row
        style={{
          paddingTop:
            localStorage.getItem("role") === "Administrateur" ||
            localStorage.getItem("role") === "Super Administrateur"
              ? "130px"
              : "",
        }}
      >
        <Col>
          {Object.entries(
            groupTransfersByDate(filteredTransfers || transfers)
          ).map(([date, transfersOnDate]) => (
            <React.Fragment key={date}>
              {/* Afficher la date comme un séparateur */}
              <div className="date-separator">
                <h6 className="text-white text-left p-1">
                  {new Date(date).toLocaleDateString("fr-FR")}
                </h6>
              </div>

              {/* Afficher les transferts pour cette date */}
              {transfersOnDate.map((course) => {
                // Générer un id unique basé sur IdResa et IdChauffeur, ou 'no-driver' si aucun chauffeur n'est affecté
                const uniqueId = course.IdChauffeur
                  ? `transfer-${course.IdResa}-${course.IdChauffeur}` // Si un chauffeur est affecté
                  : `transfer-${course.IdResa}-no-driver`; // Si aucun chauffeur n'est affecté

                return (
                  <div
                    key={uniqueId} // Utiliser un key unique
                    id={uniqueId} // Utiliser un id unique pour chaque course
                  >
                    <TransferRow
                      course={course}
                      unassignDriver={unassignDriver}
                      handleCourseClick={handleCourseClick}
                      setBgColor={setBgColor}
                      handleOpenPaymentMethod={() =>
                        handleOpenPaymentMethod(course.IdResa)
                      }
                    />
                  </div>
                );
              })}
            </React.Fragment>
          ))}
        </Col>
      </Row>

      {/* Modal de paiement PAB */}
      <PaymentMethodModal
        show={showPaymentMethod}
        handleClose={handleClosePaymentMethod}
        transferId={selectedTransferForPayment} // Passe l'ID du transfert à la modal
        choosePaymentMethod={choosePaymentMethod}
      />
    </Container>
  );
}

export default Transfers;
